<template>
  <template v-if="currentField == null">
    <page-card headingTitle="Field could not be found."> </page-card>
  </template>
  <template v-else>
    <delete-modal
      :open="modalIsActive"
      :modalTitle="deleteEntity.type == 'crop' ? 'Delete Crop' : 'Delete Field'"
      :modalDescription="modalDescription"
      @clickCancel="cancelDelete"
      @clickConfirmDelete="handleDelete"
    />
    <page-card
      :headingTitle="headingTitle"
      :headingDescription="headingDescription"
    >
      <template #buttonArea>
        <base-button
          buttonText="Back"
          :marginLeft="true"
          @buttonClick="this.$router.push({ name: 'fields' })"
        />
        <base-button
          buttonText="Save"
          :marginLeft="true"
          @buttonClick="updateField(currentField)"
        />
        <base-button
          buttonText="Delete"
          :redButton="true"
          @buttonClick="() => intializeDeleteModal('field')"
        />
      </template>
      <template #cardDetails>
        <div class="col-span-6">
          <crop-selector
            :currentCrops="currentCrops"
            @selectCrop="(index) => (selectedCropIndex = index)"
            @addCrop="newCrop"
            @deleteCrop="(index) => intializeDeleteModal('crop', index)"
          />

          <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
            <div class="border-b border-gray-200">
              <TabList class="-mb-px flex space-x-8">
                <Tab as="template" v-slot="{ selected }">
                  <button
                    :class="[
                      selected
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                      'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                    ]"
                  >
                    Details
                  </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                  <button
                    :class="[
                      selected
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                      'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                    ]"
                  >
                    Map
                  </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                  <button
                    :class="[
                      selected
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                      'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                    ]"
                  >
                    Records
                  </button>
                </Tab>
              </TabList>
            </div>
            <TabPanels as="template">
              <TabPanel as="dl" class="pt-10" :unmount="false">
                <form
                  @submit.prevent
                  id="edit-field"
                  class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
                >
                  <field-form
                    v-model:field="currentField"
                    @mapClicked="selectedTab = 1"
                  />
                  <template
                    v-for="(crop, index) in currentCrops"
                    :key="crop.id"
                  >
                    <div
                      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
                      v-show="index == selectedCropIndex"
                    >
                      <crop-form
                        v-model:crop="currentCrops[index]"
                        :isNew="crop.newCrop"
                        :clone="currentCrop"
                        @update:activeCrop="markActive(index)"
                      />
                    </div>
                  </template>
                </form>
              </TabPanel>
              <TabPanel as="dl" class="pt-10">
                <div
                  class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
                >
                  Field Boundary Map
                </div>
                <div class="col-span-6">
                  <div class="-mt-2 flex text-sm text-gray-600">
                    You can edit your field boundary
                    <img
                      :src="require('@/assets/Edit_Vertex.svg')"
                      alt="edit boundary"
                      class="ml-1 h-4 w-4"
                    />, exclude areas
                    <img
                      :src="require('@/assets/scissors.svg')"
                      alt="edit boundary"
                      class="ml-1 h-4 w-4"
                    />, or add parking and entrance markers
                    <img
                      :src="require('@/assets/location-marker.svg')"
                      alt="edit boundary"
                      class="ml-1 h-4 w-4"
                    />
                  </div>
                  <div class="flex text-sm text-gray-600">
                    There are {{ locations?.length }} locations marked.

                    <div v-if="entrance_points > 0">
                      &nbsp;{{ entrance_points }} entrance points
                    </div>
                    <div v-if="pickup_points > 0 && entrance_points > 0">
                      &nbsp;and
                    </div>
                    <div v-if="pickup_points > 0">
                      &nbsp;{{ pickup_points }} pick up points
                    </div>
                  </div>
                </div>
                <div class="col-span-6 border-t border-gray-200 px-6 py-5">
                  <field-edit-map
                    :fieldId="fieldHasGeometry ? fieldId : null"
                    @edited="updateFieldShape"
                    :key="fieldId"
                    @location-added="markLocation"
                    @removeMarker="deleteLocation"
                    :layers="layers"
                    :showLayerSelector="true"
                  >
                    <modal :show="showLocationModal" title="save a location">
                      <select-dropdown
                        v-model="markedLocationType"
                        :selectOptions="['Entrance', 'Pick up']"
                        :placeholder="'Select a location'"
                        inputTitle="Location Type"
                      />
                      <base-button
                        @buttonClick="saveLocation"
                        buttonText="Save"
                      ></base-button>
                      <base-button
                        @buttonClick="showLocationModal = false"
                        buttonText="Cancel"
                        :redButton="true"
                      ></base-button>
                    </modal>
                  </field-edit-map>
                </div>
              </TabPanel>
              <TabPanel class="pt-10">
                <records-table
                  :currentCropId="currentCrops[selectedCropIndex]?.id"
                  :currentFieldId="currentField?.id"
                  ref="recordsTable"
                  v-if="currentCrops[selectedCropIndex]?.id"
                />
              </TabPanel>
              <!-- <TabPanel class="pt-10">
              <activities-table
                :currentEquipmentId="parseInt($route.params.equipmentId)"
              />
            </TabPanel> -->
            </TabPanels>
          </TabGroup>
        </div>
      </template>
      <template #componentSection> </template>
    </page-card>
  </template>
</template>

<script>
import CropSelector from "@/layouts/grower-portal/assets/fields/CropSelector.vue";
import FieldForm from "@/layouts/grower-portal/assets/fields/FieldForm.vue";
import CropForm from "@/layouts/grower-portal/assets/fields/CropForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
// import SubmitButton from "@/components/buttons/SubmitButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import FieldEditMap from "@/components/map/FieldEditMap.vue";
import RecordsTable from "@/layouts/grower-portal/records/RecordsTable.vue";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import modal from "@/components/modals/PopupModal.vue";
import { useToast } from "vue-toastification";

let cropList = require("@/assets/croplist.js");

export default {
  components: {
    PageCard,
    BaseButton,
    // SubmitButton,
    SelectDropdown,
    FieldForm,
    CropForm,
    DeleteModal,
    CropSelector,
    FieldEditMap,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    modal,
    RecordsTable,
  },
  data() {
    return {
      selectedTab: 0,
      selectedCropIndex: null,
      modalIsActive: false,
      editFieldFormId: "editField",
      errorArray: new Array(),
      showLocationModal: false,
      currentField: {},
      markedLocationType: null,
      cropModalIsActive: false,
      copyCropDetails: false,
      newCropType: null,
      deleteEntity: { index: null, type: null },
      toast: useToast(),
      localError: false,
      currentCrops: [],
    };
  },
  computed: {
    headingTitle() {
      let title = "";
      if (this.currentField?.properties?.farm) {
        title += this.currentField.properties.farm + " - ";
      }
      title += this.currentField.properties.name + "  ";
      return title;
    },
    headingDescription() {
      let description = "";
      if (this.currentField?.properties?.current_crop?.details?.variety) {
        description +=
          this.currentField.properties?.current_crop?.details?.variety + " ";
      }
      description += this.currentField.properties?.current_crop?.crop_type;
      return description;
    },
    fieldId() {
      return parseInt(this.$route.params.fieldId);
    },
    currentCrop() {
      return structuredClone(
        this.$store.getters.getCropByFieldId(this.fieldId),
      );
    },
    currentCropsFromStore() {
      return this.$store.getters.getCropsByFieldId(this.fieldId);
    },
    existingField() {
      return this.$store.getters.getFieldById(
        parseInt(this.$route.params.fieldId),
      );
    },
    cropOptions() {
      let options = [];
      for (let crop of cropList) {
        options.push(crop.name);
      }
      return options;
    },
    fieldRecords() {
      let fieldRecords = this.$store.getters.getRecordsByField(this.fieldId);
      let field = this.$store.getters.getFieldById(this.fieldId);
      let cropRecords = this.$store.getters.getRecordsByCrop(
        field?.properties?.current_crop?.id,
      );
      let records = fieldRecords.concat(cropRecords);
      return records;

      // return this.$store.getters.getRecordsByField(this.fieldId);
    },
    fieldTasks() {
      return this.$store.getters.getTasksByField(this.fieldId);
    },
    fieldHasGeometry() {
      if (this.currentField.geometry?.coordinates?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    modalDescription() {
      let description = `Are you sure you want to delete this ${this.deleteEntity.type}?`;
      if (this.deleteEntity.type == "field") {
        if (this.fieldRecords.length == 1) {
          description += ` This field has 1 record, which will also be deleted.`;
        }
        if (this.fieldRecords.length > 1) {
          description += ` This field has ${this.fieldRecords.length} records, which will also be deleted.`;
        }
        if (this.fieldTasks.length == 1) {
          description += ` This field has 1 task, which will also be deleted.`;
        }
        if (this.fieldTasks.length > 1) {
          description += ` This field has ${this.fieldTasks.length} tasks, which will also be deleted.`;
        }
      }
      return description;
    },
    layers() {
      let layers = [];
      let fields = this.$store.state.fields.fields;
      fields = fields.filter((field) => field.id != this.fieldId);
      let fieldJSON = {
        type: "FeatureCollection",
        features: fields.map((field) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: field.properties.name,
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Other Fields",
        geojson: fieldJSON,
        style: { fillColor: "red", opacity: 1, weight: 1, color: "green" },
      };
      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);
      return layers;
    },
    locations() {
      let locations = this.$store.getters.getGrowerLocationsByFieldId(
        this.fieldId,
      );
      return locations;
    },
    entrance_points() {
      let locations = this.$store.getters.getGrowerLocationsByFieldId(
        this.fieldId,
      );
      let entrance_points = 0;
      if (locations?.length > 0) {
        if (locations.properties) {
          entrance_points = locations.filter(
            (location) => location.properties?.label == "Entrance",
          )?.length;
        } else {
          entrance_points = locations.filter(
            (location) => location.type == "Entrance",
          )?.length;
        }
      }
      // console.log("entrance points", entrance_points, locations);
      return entrance_points;
    },
    pickup_points() {
      let locations = this.$store.getters.getGrowerLocationsByFieldId(
        this.fieldId,
      );
      let pickup_points = 0;
      if (locations?.length > 0) {
        if (locations.properties) {
          pickup_points = locations.filter(
            (location) => location.properties?.label == "Pick up",
          )?.length;
        } else {
          pickup_points = locations.filter(
            (location) => location.type == "Pick up",
          )?.length;
        }
      }
      return pickup_points;
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
    },
    intializeDeleteModal(type, index) {
      // console.log("intializeDeleteModal", type, id);
      this.modalIsActive = true;
      this.deleteEntity.index = index;
      this.deleteEntity.type = type;
    },
    handleDelete() {
      if (this.deleteEntity.type == "crop") this.deleteCrop();
      else this.deleteField();
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    updateField() {
      if (this.currentField.properties.name == "") {
        this.toast.error("Field name is required");
        this.localError = true;
        return;
      }
      if (this.currentField.properties.acres <= 0) {
        this.toast.error("Acres must be greater than 0");
        this.localError = true;
        return;
      }
      // if (this.fieldHasGeometry) {
      // this.currentField.properties = updatedProperties;
      let currentCrop = this.currentCrops.find((crop) => crop.active == true);
      if (currentCrop) {
        this.currentField.properties.details = structuredClone(
          currentCrop.details,
        );
        this.currentField.properties.crop = currentCrop.crop_type;
      }
      // console.log("updateField", this.currentField);
      this.$store.dispatch("updateField", this.currentField);
      for (let crop of this.currentCrops) {
        // console.log("updating crop", crop);
        this.updateCrop(crop);
      }
      // toast success
      this.toast.success("Field updated successfully");
      if (this.$route.query.order) {
        this.$router.push({
          name: "grower-portal-produce-order",
          params: { orderId: this.$route.query.order },
        });
      }
      // else {
      //   this.$router.push({ name: "fields" });
      // }
      // } else {
      //   this.displayNoGeomModal();
      // }
    },
    newCrop() {
      let newCrop = {
        field: this.fieldId,
        crop_type: this.newCropType,
        details: {},
      };
      newCrop.newCrop = true;
      this.currentCrops.push(newCrop);
    },
    async addCrop(cropDetails) {
      await this.$store.dispatch("createCrop", {
        fieldId: this.fieldId,
        crop: cropDetails,
      });
      cropDetails.newCrop = false;
    },
    async updateCrop(updatedCrop) {
      // if (!updatedCrop.crop_type) {
      //   this.toast.error("Please select a crop type");
      //   this.localError = true;
      //   return;
      // }
      if (updatedCrop.newCrop) {
        this.addCrop(updatedCrop);
      } else {
        // console.log(updatedCrop);
        if (updatedCrop.changed && updatedCrop.id) {
          await this.$store.dispatch("updateCrop", updatedCrop);
        }
      }
    },
    markActive(index) {
      this.currentCrops.forEach((crop) => {
        crop.active = false;
      });
      this.currentCrops[index].active = true;
    },
    async deleteCrop() {
      // console.log("delete crop");
      this.modalIsActive = false;
      let deleteCrop = this.currentCrops[this.deleteEntity.index];
      if (deleteCrop.id) {
        await this.$store.dispatch("deleteCrop", { id: deleteCrop.id });
        this.currentCrops.splice(this.deleteEntity.index, 1);
      } else {
        this.currentCrops.splice(this.deleteEntity.index, 1);
      }
      this.deleteEntity = { index: null, type: null };
    },
    async deleteField() {
      this.modalIsActive = false;
      // console.log("DELETE");
      // THIS IS NOW HANDLED BY BACK END
      //if (this.fieldRecords.length > 0) {
      //   this.fieldRecords.forEach(async (record) => {
      //     await this.$store.dispatch("deleteRecord", {
      //       id: record.id,
      //     });
      //   });
      // }
      // if (this.fieldTasks.length > 0) {
      //   this.fieldTasks.forEach(async (task) => {
      //     await this.$store.dispatch("deleteTask", {
      //       id: task.id,
      //     });
      //   });
      // }

      await this.$store.dispatch("deleteField", {
        id: this.fieldId,
      });
      if (!this.$store.getters.getApiError && !this.localError) {
        this.$router.push({ name: "fields" });
      } else {
        // we warned them, now let them fix it and try again
        this.localError = false;
      }
    },
    updateFieldShape(feature, acres) {
      this.currentField.properties.acres = acres;
      this.currentField.geometry = feature.geometry;
    },
    markLocation(location) {
      this.markedLocationPoint = location;
      this.showLocationModal = true;
    },
    saveLocation() {
      this.showLocationModal = false;
      this.$store.dispatch("createGrowerLocation", {
        field: this.fieldId,
        type: this.markedLocationType,
        point: this.markedLocationPoint,
      });
      this.markedLocationType = null;
      this.markedLocationPoint = null;
    },
    deleteLocation(id) {
      this.$store.dispatch("deleteGrowerLocation", { id });
    },
  },
  watch: {
    fieldId: {
      handler() {
        let main = document.getElementById("main-content");
        // console.log("route changed", main, main?.scrollTop);
        if (main) main.scrollTo(0, 0);
      },
      immediate: true,
    },
    existingField: {
      handler() {
        this.currentField = structuredClone(this.existingField);
      },
      immediate: true,
    },
    currentCropsFromStore: {
      handler(newVal) {
        this.currentCrops = newVal;
      },
      immediate: true,
    },
  },
};
</script>
